import React, { useContext, useEffect, useState } from 'react';
import Profile from './Profile';
import { AuthContext } from '../context/AuthContext';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

const EventAdmin = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const fetchEvents = async () => {
        try {
          const eventsCollection = collection(db, 'events');
          const q = query(eventsCollection, where('organizerId', '==', user.uid));
          const eventsSnapshot = await getDocs(q);
          const eventsData = eventsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setEvents(eventsData);
        } catch (err) {
          console.error('Failed to fetch events:', err);
        }
      };

      fetchEvents();
    }
  }, [isAuthenticated, user]);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-primary mb-4">History</h1>

      {isAuthenticated && (
        <div className="grid mt-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {events.map(event => (
            <div key={event.id} className="p-4 m-3 bg-white rounded-lg shadow-md">
              <h2 className="text-xl font-semibold mb-2">{event.name}</h2>
              <p className="text-gray">Total RSVPs: {event.rsvps ? event.rsvps.length : 0}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventAdmin;