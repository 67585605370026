import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

const News = ({ isAuthenticated }) => {
    const [newsItems, setNewsItems] = useState([]);

    useEffect(() => {
        const fetchedNews = [
            { 
                id: 1, 
                title: "Little River", 
                description: (
                    <>
                    Explored a re-use sustainablilty community event in <Link to="/miami/littleriver" className="text-teal font-bold underline hover:text-blue"> Little River</Link> arts district in Miami.
                    </>
                    )
                    , videoId: "8_C3Eqo9-Hk",
            },
            { 
                id: 2, 
                title: "Buena Vista ", 
                description: (
                    <>
                    Relive 80s Miami at WYN317 Art Gallery in <Link to="/miami/buenavista" className="text-teal font-bold underline hover:text-blue"> Buena Vista!</Link>
                    </>
                    )
                    , videoId: "fdSN7cD5Sr8",
            },
            { id: 3, title: "Little Havana Vibes", 
                description: (
                    <>
                    Experience the culture and music in! <Link to="/miami/littlehavana" className="text-teal font-bold underline hover:text-blue"> Little Havana</Link>
                    </>
                )
                , 
                videoId: "gVR799Ffi6w" },
        ];
        setNewsItems(fetchedNews);
    }, []);

    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Neighborhood Watch</h1>
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Stay up-to-date with the latest events and business news in your neighborhood!</h3>
            {/* Call to Action for Login */}
            {!isAuthenticated && (
                <div className="bg-mustard text-coral p-4 mb-6 rounded-lg text-center">
                    <p>
                        Want more personalized news and rewards?{" "}
                        <Link to="/login" className="font-semibold underline hover:text-blue-900">
                            Log in
                        </Link>{" "}
                        to unlock the full experience!
                    </p>
                </div>
            )}

            {/* Display News Items */}
            {newsItems.map(item => (
                <div key={item.id} className="bg-white rounded-lg shadow-md p-6 mb-6">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-2">{item.title}</h2>
                    <p className="text-gray-600 mb-4">{item.description}</p>
                    <div className="relative" style={{ paddingBottom: '56.25%', height: 0 }}>
                        <YouTube 
                            videoId={item.videoId} 
                            opts={{ 
                                width: "100%",
                                height: "100%", 
                                playerVars: { autoplay: 0 }
                            }} 
                            className="absolute top-0 left-0 w-full h-full"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default News;