import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import community from '../assets/city_people.png';
import Login from './Login';
import { neighborhoodData } from './Neighborhoods'; // Import neighborhoods data

function Profile() {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [selectedNeighborhoods, setSelectedNeighborhoods] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch user data from Firestore using UID
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);  // Always use UID as document ID
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(data);
            setEventTypes(data.eventTypes || []);
            setSelectedNeighborhoods(data.neighborhoods || []);
            setBusinessTypes(data.businessTypes || []);
          } else {
            // If no document exists, create a new one
            const newUserData = {
              displayName: user.displayName,
              email: user.email,
              neighborCoinBalance: 0,
            };
            await setDoc(userRef, newUserData);
            setUserData(newUserData);
          }
        } catch (err) {
          console.error('Error fetching user data:', err);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, []);

  // Save preferences to Firestore
  const savePreferences = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, 'users', user.uid);
        await updateDoc(userDoc, {
          eventTypes,
          neighborhoods: selectedNeighborhoods,
          businessTypes,
        });
        setMessage('Preferences saved successfully!');
        setModalOpen(true);
      }
    } catch (error) {
      console.error('Error saving preferences:', error);
    }
  };

  const closeModal = () => setModalOpen(false);

  // Handle neighborhood checkbox changes
  const handleNeighborhoodChange = (neighborhood) => {
    setSelectedNeighborhoods((prevSelected) =>
      prevSelected.includes(neighborhood)
        ? prevSelected.filter((n) => n !== neighborhood)
        : [...prevSelected, neighborhood]
    );
  };

  if (loading) return <p>Loading...</p>;

  if (!auth.currentUser) {
    return (
      <div className="max-w-md mx-auto bg-white p-4 rounded-lg shadow-lg text-center">
        <div>
          <img src={community} alt="Neighbors gathering" />
        </div>
        <p className="mb-4">You are not logged in.</p>
        <div>
          <Login />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto bg-white p-4 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-accent mb-6 text-center">Profile</h2>
      {userData ? (
        <>
          <div className="mb-4">
            <h3 className="block font-bold text-2xl text-neutral mb-2">{userData.name}</h3>
            <p className="text-neutral">{userData.email}</p>
          </div>
          <div className="mb-4">
            <h4 className="text-neutral font-bold mb-2">Event interests:</h4>
            <div className="flex flex-col space-y-2">
              {['Art', 'Marketplace', 'Music', 'Food'].map((eventType) => (
                <label key={eventType} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value={eventType}
                    checked={eventTypes.includes(eventType)}
                    onChange={() => setEventTypes((prev) =>
                      prev.includes(eventType) ? prev.filter(e => e !== eventType) : [...prev, eventType]
                    )}
                    className="form-checkbox h-5 w-5 text-primary border-gray-300 rounded"
                  />
                  <span>{eventType}</span>
                </label>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <h4 className="text-neutral font-bold mb-2">Favorite Miami Neighborhoods</h4>
            <div className="flex flex-col space-y-2">
              {Object.values(neighborhoodData.miami).map((neighborhood) => (
                <label key={neighborhood.slug} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value={neighborhood.name}
                    checked={selectedNeighborhoods.includes(neighborhood.name)}
                    onChange={() => handleNeighborhoodChange(neighborhood.name)}
                    className="form-checkbox h-5 w-5 text-primary border-gray-300 rounded"
                  />
                  <span>{neighborhood.name}</span>
                </label>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <h4 className="text-neutral font-bold mb-2">Local businesses you support</h4>
            <div className="flex flex-col space-y-2">
              {['Restaurant', 'Retail', 'Service', 'Entertainment'].map((businessType) => (
                <label key={businessType} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    value={businessType}
                    checked={businessTypes.includes(businessType)}
                    onChange={() => setBusinessTypes((prev) =>
                      prev.includes(businessType) ? prev.filter(b => b !== businessType) : [...prev, businessType]
                    )}
                    className="form-checkbox h-5 w-5 text-primary border-gray-300 rounded"
                  />
                  <span>{businessType}</span>
                </label>
              ))}
            </div>
          </div>
          <button
            onClick={savePreferences}
            className="bg-primary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300"
          >
            Save Preferences
          </button>
          {/* Modal for success message */}
          {modalOpen && (
            <div className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold text-center">Success!</h3>
                <p className="text-center mt-4">{message}</p>
                <button
                  className="mt-4 bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <p>No user data found.</p>
      )}
    </div>
  );
}

export default Profile;