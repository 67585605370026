import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';  
import { doc, getDoc, setDoc } from 'firebase/firestore';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons'; 

const GoogleSignInButton = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user already exists in Firestore and has a role
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if(!userDoc.exists()) {
        // If user does not exist, create a new user document in Firestore
        await setDoc(userRef, {
          email: user.email,
          name: user.displayName,
          neighborCoinBalance: 0,
          role: 'attendee', // Default role
          createdAt: new Date()
        });
        // If no role exists, redirect to the role-signup page to choose a role
        navigate('/signups', { state: { email: user.email } });
      } else {

     //Fetch the user's role from Firestore and redirect to appropriate page
      const userRole = userDoc.exists() ? userDoc.data().role : 'attendee';
        // Redirect user based on their role
        switch (userRole) {
          case 'attendee':
            navigate('/dashboard');
            break;
          case 'eventPlanner':
            navigate('/event-admin');
            break;
          case 'businessOwner':
            navigate('/business-dashboard');
            break;
          default:
            navigate('/dashboard');
        }
      }     
      } catch (error) {
      console.error("Google Sign-In Error:", error);
    }
  };

  return (
    <div className="bg-teal px-4 py-4">
      <h2 className="text-center text-xl font-bold text-white">Login or Signup </h2>
      <div className="flex flex-col justify-center items-center m-3">
        <FontAwesomeIcon icon={faArrowDown} className="text-white text-xl mb-3 animate-bounce" />
        <button
          onClick={handleGoogleSignIn}
          className="flex items-center justify-center px-4 py-2 bg-mustard text-gray-700 rounded-lg shadow hover:bg-gray-100 border border-gray-300"
        >
          <FontAwesomeIcon icon={faGoogle} className="mr-2 text-red" />
          Sign in with Google
        </button>
      </div>
    </div>
  );
};

export default GoogleSignInButton;