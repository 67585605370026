import React, { useEffect, useState } from 'react';
import { auth, db, storage } from '../firebase'; // Import Firebase instance
import { doc, getDoc, collection, query, where, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase Storage methods
import { Link, useNavigate } from 'react-router-dom';
import MyOffers from '../components/MyOffers';
import { Timestamp } from 'firebase/firestore'; 

const BusinessDashboard = () => {
  const [businessData, setBusinessData] = useState(null);
  const [events, setEvents] = useState([]);
  const [sponsorableEvents, setSponsorableEvents] = useState([]); // Events available for sponsorship
  
  const [logoFile, setLogoFile] = useState(null); // State for storing the selected logo file
  const [uploading, setUploading] = useState(false); // State for tracking upload status
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return ''; // Handle missing or invalid timestamp
    const date = new Date(timestamp.seconds * 1000); // Convert Firestore Timestamp to JS Date
    return date.toLocaleDateString(); // Format the date (you can adjust this formatting as needed)
  };

  
// Fetch regular business events
useEffect(() => {
  const fetchBusinessData = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Get business owner details
        const businessDoc = await getDoc(doc(db, 'businesses', user.uid));
        if (businessDoc.exists()) {
          setBusinessData(businessDoc.data());
        } else {
          // If the business data doesn't exist, create a new entry for the business
          const newBusinessData = {
            name: '', // default name, can be updated by the user
            ownerEmail: user.email,
            createdAt: new Date(),
            logoUrl: '',
          };
          await setDoc(doc(db, 'businesses', user.uid), newBusinessData);
          setBusinessData(newBusinessData); // Set the default data to state
        }

        // Fetch events associated with this business and filter for upcoming events
        const q = query(
          collection(db, 'events'), 
          where('businessId', '==', user.uid),
          where('date', '>', Timestamp.now()) // Only fetch events in the future
        );
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setEvents(eventsList);

      } catch (err) {
        setError(err.message);
      }
    } else {
      setError('User not authenticated');
    }
  };

  fetchBusinessData();
}, []);

// Fetch sponsorable events
useEffect(() => {
  const fetchSponsorableEvents = async () => {
    try {
      const q = query(
        collection(db, 'events'),
        where('isSponsorable', '==', true),
        where('sponsoredBy', '==', null), // Only fetch events that are sponsorable and not yet sponsored
        where('date', '>', Timestamp.now()) // Only fetch upcoming events
      );
      const querySnapshot = await getDocs(q);
      const eventsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setSponsorableEvents(eventsList);
    } catch (error) {
      console.error('Error fetching sponsorable events: ', error);
    }
  };

  fetchSponsorableEvents();
}, []);

  const handleCreateOffer = () => {
    // Logic to ensure they have business profile set up
    if (!businessData) {
      setError('Please set up your business profile first.');
      return;
    }
    // Logic to create new offers for event attendees and pass businessData to the new offer creation page
    navigate('/create-offer', {state: {businessData }});  // Example of navigating to a new offer creation page
  };

  const handleSponsorEvent = async (eventId) => {
    try {
      // Update the event in Firestore to link the business as a sponsor
      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, {
        sponsorId: auth.currentUser.uid,
        sponsorName: businessData.businessName,
      });
      setMessage('You have successfully sponsored this event.');
    } catch (error) {
      setError(`Failed to sponsor event: ${error.message}`);
    }
  };

  const handleLogoChange = (e) => {
    setLogoFile(e.target.files[0]); // Store the selected logo file
  };

  const handleLogoUpload = async () => {
    if (!logoFile) {
      setError('Please select a logo file to upload.');
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      setError('User not authenticated');
      return;
    }

    const logoRef = ref(storage, `business_logos/${user.uid}/${logoFile.name}`); // Reference to Firebase Storage

    setUploading(true); // Start uploading

    const uploadTask = uploadBytesResumable(logoRef, logoFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optional: Handle progress here if needed
      },
      (error) => {
        console.error('Logo upload failed: ', error);
        setError('Logo upload failed. Please try again.');
        setUploading(false);
      },
      async () => {
        // Upload completed successfully, get the download URL
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        
        // Update the business profile with the logo URL in Firestore
        const businessRef = doc(db, 'businesses', user.uid);
        await updateDoc(businessRef, {
          logoUrl: downloadURL,
        });

        setMessage('Logo uploaded successfully!');
        setUploading(false);
        setLogoFile(null); // Clear the selected file
        setBusinessData((prevData) => ({ ...prevData, logoUrl: downloadURL })); // Update businessData with the new logo URL
      }
    );
  };

  

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-primary mb-6">Business Dashboard</h2>

      {error && <p className="text-red-500">{error}</p>}
      {message && <p className="text-green-500">{message}</p>}

      {/* Section 1: Business Information */}
      <div className="bg-tealLight p-6 rounded-lg shadow-sm">
        <h3 className="text-xl font-semibold mb-4">About Your Business</h3>
        {businessData && (
          <>
            {businessData.logoUrl ? (
              <div className="flex items-center space-x-4 mb-6">
                <img src={businessData.logoUrl} alt="Business Logo" className="w-24 h-24 object-cover rounded-full" />
                <h4 className="text-xl font-bold">{businessData.businessName || 'Business Name'}</h4>
              </div>
            ) : (
              <div className="mb-6">
                <label className="block text-neutral mb-2">Upload Business Logo</label>
                <input type="file" onChange={handleLogoChange} className="w-full px-4 py-2 border border-gray-300 rounded-lg" />
                <button
                  onClick={handleLogoUpload}
                  className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight mt-4"
                  disabled={uploading}
                >
                  {uploading ? 'Uploading...' : 'Upload Logo'}
                </button>
              </div>
            )}

            <button 
              onClick={() => navigate('/edit-business-profile')}
              className="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-highlight mt-4"
            >
              Edit Profile
            </button>
          </>
        )}
      </div>
      {/* Section 1: Use AI to Generate Business Promotion  */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
        <h3 className="text-xl font-semibold mb-4">Generate Business Promotion</h3>
        <p className="text-gray-600">Use our AI tool to generate promotional content for your business.</p>
       
          <Link to="/business-promotion" className="bg-primary text-white py-2 px-4 mt-4 inline-block rounded-lg hover:bg-secondary">
            Create Promotional Content
          </Link>
      </div>


      {/* Section 2: Events Section */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
        <h3 className="text-xl font-semibold mb-4">Your Events</h3>
        {events.length > 0 ? (
          <ul className="list-disc list-inside space-y-2">
          {events.map((event) => (
            <li key={event.id}>
              <Link to={`/miami/events/${event.id}`} className="text-primary hover:underline">
                {event.name}
              </Link> - {formatDate(event.date)} {/* Convert and render the date */}
            </li>
          ))}
        </ul>
        ) : (
          <p className="text-gray-600">No events linked to your business.</p>
        )}
      </div>
      {/* Section 3: Sponsorable Events Section */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
        <h3 className="text-xl font-semibold mb-4">Sponsor an Event</h3>
        {sponsorableEvents.length > 0 ? (
          <ul className="list-disc list-inside space-y-2">
            {sponsorableEvents.map((event) => (
              <li key={event.id}>
                <Link to={`/events/${event.id}`} className="text-primary hover:underline">
                  {event.name}
                </Link> - {formatDate(event.date)}
                <button
                  onClick={() => handleSponsorEvent(event.id)}
                  className="ml-4 bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
                >
                  Sponsor Event
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No events available for sponsorship at the moment.</p>
        )}
      </div>

      {/* Section 4: Offers Section */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-sm">
       
        <MyOffers />
        <button 
          onClick={handleCreateOffer}
          className="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-highlight mt-4"
        >
          Create Offer
        </button>
      </div>
    </div>
  );
};

export default BusinessDashboard;