import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path to your firebase configuration file
import { Link } from 'react-router-dom';

const ArtBaselEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArtBaselEvents = async () => {
      try {
        const q = query(collection(db, 'events'), where('isArtBasel', '==', true));
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEvents(eventsList);
      } catch (error) {
        console.error('Error fetching Art Basel events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArtBaselEvents();
  }, []);

  const isEventEnded = (eventDate) => {
    const now = new Date();
    const eventDateObj = eventDate.toDate ? eventDate.toDate() : new Date(eventDate); // Convert Firestore Timestamp to Date if necessary
    return eventDateObj < now; // Check if event date is in the past
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mt-8">Art Basel Events</h1>
      <p className="text-lg mt-4">
        Welcome to the Art Basel Miami community events page. Here you will find neighborhoods events related to Art Basel Miami - December 6-8, 2024.
      </p>

      {loading ? (
        <p>Loading events...</p>
      ) : (
        <>
          {events.length > 0 ? (
            events
              .sort((a, b) => {
                const dateA = new Date(a.date.seconds * 1000); // Convert Firestore Timestamp to Date object
                const dateB = new Date(b.date.seconds * 1000); // Convert Firestore Timestamp to Date object
                return dateB - dateA; // Sort in descending order
              })
              .map((event) => {
                const eventDate = new Date(event.date.seconds * 1000); // Convert Firestore Timestamp to Date object
                const eventEnded = isEventEnded(eventDate);

                return (
                  <div key={event.id} className="flex items-center justify-between p-4 m-3 border-b border-gray-300 rounded-lg shadow-lg bg-white">
                    <div className="flex-1 mr-4">
                      <Link to={`/events/${event.id}`}>
                        <h3 className="text-lg font-semibold text-teal">{event.name}</h3>
                      </Link>
                      <p className="text-gray-500 text-bold mb-3">
                        {eventDate.toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit',
                        })}
                      </p>
                      <p className="text-gray-600">{event.location}</p>

                      {eventEnded && (
                        <span className="text-red-600 font-bold">Event Ended</span>
                      )}
                    </div>
                    <div className="w-24 h-24">
                      <Link to={`/events/${event.id}`}>
                        <img src={event.imageUrl || "path_to_default_image.jpg"} alt="Thumbnail" className="w-full h-full object-cover" />
                      </Link>
                    </div>
                  </div>
                );
              })
          ) : (
            <p>No Art Basel events available</p>
          )}
        </>
      )}
    </div>
  );
};

export default ArtBaselEvents;