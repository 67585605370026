import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import community from '../assets/city_people.png';
import Login from './Login'; // Import the Login component

const Rewards = () => {
  const [rewards, setRewards] = useState([]);
  const [businesses, setBusinesses] = useState({}); // Store business data
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [showLogin, setShowLogin] = useState(null); // State to show login for a specific offer

  useEffect(() => {
    const fetchRewardsAndBusinesses = async () => {
      try {
        // Fetch all rewards from Firestore
        const rewardsCollection = collection(db, 'offers');
        const rewardsSnapshot = await getDocs(rewardsCollection);
        const rewardsList = rewardsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Collect business IDs from the rewards
        const businessIds = [...new Set(rewardsList.map(reward => reward.businessId))];
        
        // Fetch the corresponding business details for each businessId
        const businessesData = {};
        for (const businessId of businessIds) {
          const businessDoc = await getDoc(doc(db, 'businesses', businessId));
          if (businessDoc.exists()) {
            businessesData[businessId] = businessDoc.data();
          }
        }

        setRewards(rewardsList);
        setBusinesses(businessesData); // Store the business details in the state
      } catch (err) {
        setError('Failed to fetch rewards or business data: ' + err.message);
      }
    };

    fetchRewardsAndBusinesses();
  }, []);

  const handlePurchaseReward = async (reward) => {
    const user = auth.currentUser;
    if (user) {
      try {
        // Check if the user has enough NeighborCoins
        const userRef = doc(db, 'users', user.email);
        const userDoc = await getDoc(userRef);
        const userBalance = userDoc.data().neighborCoinBalance;

        if (userBalance >= reward.coinOffer) {
          // Deduct the cost from the user's NeighborCoin balance
          await updateDoc(userRef, {
            neighborCoinBalance: increment(-reward.coinOffer)
          });
          setMessage(`You have successfully purchased ${reward.offerTitle}!`);
        } else {
          setError('Insufficient NeighborCoins');
        }
      } catch (err) {
        setError('Failed to purchase reward: ' + err.message);
      }
    } else {
      setShowLogin(reward.id); // Show the login for this specific reward
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-2 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary mb-6">Local Reward Partners</h2>
      {rewards.length === 0 && <p className="text-neutral">No rewards available at this time.</p>}
      <div className="space-y-6">
        {rewards.map(reward => {
          const business = businesses[reward.businessId]; // Get the business data for the reward
          return (
            <div key={reward.id} className="p-4 bg-gray-100 rounded-lg shadow-md">
              {/* Offer Image */}
              <img 
                src={reward.offerImageUrl || 'https://via.placeholder.com/150'} 
                alt={reward.offerTitle} 
                className="w-full h-60 object-cover rounded-lg mb-4" 
              />
              <h3 className="text-2xl font-semibold text-teal mb-2">{reward.offerTitle}</h3>
              <p className="text-neutral mb-4">{reward.offerDescription}</p>
              <p className="text-teal mb-4">Cost: <span className="font-bold text-teal">{reward.coinOffer}</span> NeighborhoodCoins</p>
              {business && (
                <div className="mb-4">
                  <h4 className="font-semibold">Offered by: {business.businessName}</h4>
                  {business.logoUrl && (
                    <img src={business.logoUrl} alt={`${business.businessName} logo`} className="w-12 h-12 object-cover rounded-full" />
                  )}
                </div>
              )}
              <div className="mt-4">
                {showLogin === reward.id ? (
                  <div className="bg-white p-4 rounded-lg shadow-lg">
                    <Login />
                  </div>
                ) : (
                  <button
                    onClick={() => handlePurchaseReward(reward)}
                    className="bg-primary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300"
                  >
                    Purchase Reward
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {message && <p className="text-green-600 mt-4">{message}</p>}
      {error && <p className="text-red-600 mt-4">{error}</p>}
    </div>
  );
};

export default Rewards;