import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, increment } from 'firebase/firestore';
import Map from '../components/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon } from 'react-share';
import CoinImage from '../assets/coin_blank.png';
import { Helmet } from 'react-helmet-async';
import GoogleSignInButton from '../components/GoogleSignInButton';
import { AuthContext } from '../context/AuthContext';
import SponsorForm from '../components/SponsorForm';
import Banner from '../assets/neighborhood_coin_banner.png';

const EventDetails = () => {
  const {isAuthenticated, user} = useContext(AuthContext);
  const shareUrl = window.location.href;  // Current page URL for sharing
  const { eventId } = useParams();  // Get the event ID from the URL
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rsvpStatus, setRsvpStatus] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false); // Check-in state
  const [showSignup, setShowSignup] = useState(false); // Show or hide sign-up form
  const [isSponsored, setIsSponsored] = useState(false); // Track sponsorship
  const [message, setMessage] = useState('');


  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventRef);
  
        if (eventSnapshot.exists()) {
          const eventData = eventSnapshot.data();
          setEvent(eventData);
          setIsSponsored(!!eventData.sponsoredBy); 
          setRsvpStatus(eventData.rsvps && user && eventData.rsvps.includes(user?.email));
  
        } else {
          setError('Event not found');
        }
      } catch (err) {
        setError('Failed to fetch event details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchEvent();
  }, [eventId, user]);

  const handleSponsorEvent = async () => {
    const user = auth.currentUser;
    if (!user) {
      setError('You need to be logged in to sponsor an event.');
      return;
    }
  
    try {
      const businessDoc = await getDoc(doc(db, 'businesses', user.uid));
      if (!businessDoc.exists()) {
        setError('Business profile not found.');
        return;
      }
  
      const businessData = businessDoc.data();
      const eventRef = doc(db, 'events', eventId);
  
      // Use arrayUnion to add the sponsor object without overwriting existing sponsors
      await updateDoc(eventRef, {
        sponsoredBy: arrayUnion({
          sponsorId: user.uid,
          sponsorName: businessData.businessName,
        }),
      });
  
      setIsSponsored(true);
      setMessage('You have successfully sponsored this event!');
    } catch (error) {
      setError(`Failed to sponsor event: ${error.message}`);
    }
  };

  const handleRsvp = async () => {
    const user = auth.currentUser;

    if (!user) {
      setShowSignup(true);  // Show signup form if user is not authenticated
      return;
    }

    try {
      const eventRef = doc(db, 'events', eventId);

      // Update the event document by adding the user's email to the RSVPs array
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      setRsvpStatus(true);
      setMessage(`You have successfully RSVP\'d to this event! You will receive your ${event.coins} NeighborhoodCoins when you Check in to the event.`); // Add success message
    } catch (err) {
      setError('Failed to RSVP: ' + err.message);
    }
  };

  const handleCheckIn = async () => {
    if (!user) {
      setShowSignup(true);  // Show sign-up form if user is not authenticated
      return;
    }
  
    try {
      // Create a unique check-in ID for this event and user
      const checkInRef = doc(db, 'check-ins', `${eventId}_${user.uid}`);
      
      // Store the check-in details in Firestore
      await setDoc(checkInRef, {
        eventId: eventId,
        userId: user.uid,
        checkInTime: new Date(), // Store the current time
      });
  
      const eventRef = doc(db, 'events', eventId);
      const userRef = doc(db, 'users', user.uid);
  
      // Mark the user as checked in by adding their email to the event's `checkIns` array
      await updateDoc(eventRef, {
        checkIns: arrayUnion(user.email),  // Add user email to check-ins
      });
  
      // Add NeighborhoodCoins to the user's balance
      await updateDoc(userRef, {
        neighborCoinBalance: increment(event.coins || 0), // Increment balance by event's coin value
      });
  
      setCheckedIn(true); // Update state to reflect successful check-in
      setMessage(`You have successfully checked in and earned ${event.coins || 0} NeighborhoodCoins!`);
    } catch (err) {
      setError('Failed to check in: ' + err.message);
    }
  };

  const isEventActive = (eventDate, eventStartTime, eventEndTime) => {
    const currentTime = new Date();
  
    // Check if eventStartTime and eventEndTime are strings before trying to split
    if (typeof eventStartTime === 'string' && typeof eventEndTime === 'string') {
      const eventStartDateTime = new Date(eventDate.toDate());
      eventStartDateTime.setHours(...eventStartTime.split(':').map(Number));
  
      const eventEndDateTime = new Date(eventDate.toDate());
      eventEndDateTime.setHours(...eventEndTime.split(':').map(Number));
  
      return currentTime >= eventStartDateTime && currentTime <= eventEndDateTime;
    } else {
      console.error('Invalid time format for event start or end time');
      return false;
    }
  };
  const closeModal = () => {
    setShowSignup(false);
  };

  if (loading) return <p>Loading event details...</p>;
  if (error) return <p>{error}</p>;

  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'America/New_York'
  });

  const eventDateString = event && new Date(event.date).toLocaleDateString('en-US', {
    weekday:'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'America/New_York'
  });
  const normalizedNeighborhood = (neighborhood) => {
    return neighborhood.toLowerCase().replace(/\s+/g, '');
  }
  const handleSponsorClick = () => {
    navigate(`/sponsor?eventId=${eventId}`);
  };
  return (
    <div className="min-h-screen bg-neutralLight font-sans">
      {/* Hero Section with Event Image */}
      <div 
        className="bg-teal py-16 mb-12"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 128, 128, 0.9), rgba(0, 128, 128, 0.2)), url(${Banner })`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="container mx-auto px-4">
          <h1 className="font-display text-4xl font-bold text-white text-center">
            {event?.name}
          </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 pb-16">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {event && (
            <>
              <Helmet>
                <title>{event.name} - Neighborhood Coins</title>
                <meta name="description" content={event.description} />
                <meta property="og:image" content={event.imageUrl} />
                <meta property="og:url" content={shareUrl} />
              </Helmet>

              {/* Event Details Section */}
              <div className="p-8">
                {/* Event Image Thumbnail */}
                <div className="mb-8">
                  <img 
                    src={event.imageUrl} 
                    alt={event.name}
                    className="w-full rounded-lg shadow-md object-cover"
                    style={{ maxHeight: '400px' }}
                  />
                </div>

                {/* Date and Time */}
                <div className="mb-6">
                  <h2 className="font-display text-2xl font-bold mb-2">When</h2>
                  <p className="text-neutral text-lg">
                    {(() => {
                      const date = event.date instanceof Object && event.date.toDate
                        ? event.date.toDate()
                        : new Date(event.date);
                      return date.toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                        timeZone: 'America/New_York'
                      });
                    })()}
                  </p>
                  <p className="text-neutral">
                    {event.doorsOpenTime && new Date(`1970-01-01T${event.doorsOpenTime}`).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })} - {event.endTime && new Date(`1970-01-01T${event.endTime}`).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </p>
                </div>

                {/* Location and Coins */}
                <div className="flex justify-between items-start mb-6">
                  <div>
                    <h2 className="font-display text-2xl font-bold  mb-2">Where</h2>
                    <Link 
                      to={`/miami/${normalizedNeighborhood(event.neighborhood)}`}
                      className="text-lg text-neutral hover:text-mustard transition-colors flex items-center"
                    >
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-teal" />
                      <div className="text-teal font-bold">{event.neighborhood}</div>
                    </Link>
                  </div>
                  <div className="coin-display bg-teal bg-opacity-10 rounded-full p-4 text-center">
                    <p className="text-sm text-teal mb-1">Earn</p>
                    <div className="flex items-center justify-center">
                      <img src={CoinImage} alt="coins" className="w-8 h-8 mr-2" />
                      <span className="font-bold text-2xl text-teal">+{event.coins}</span>
                    </div>
                  </div>
                </div>

                {/* Description */}
                <div className="mb-6">
                  <h2 className="font-display text-2xl font-bold mb-2">About</h2>
                  <p className="text-neutral">{event.description}</p>
                </div>

                {/* Map */}
                <div className="mb-6">
                  <Map event={event} className="rounded-lg overflow-hidden shadow-md" />
                </div>

                {/* Action Buttons */}
                <div className="flex flex-wrap gap-4 mt-8">
                  {!checkedIn && isEventActive(event.date, event.startTime, event.endTime) && (
                    <button
                      onClick={handleCheckIn}
                      className="bg-mustard text-white font-bold py-3 px-6 rounded-lg hover:bg-highlight transition-colors duration-300"
                    >
                      Check-In Now
                    </button>
                  )}
                  
                  {!rsvpStatus && (
                    <button
                      onClick={() => handleRsvp(event)}
                      className="bg-teal text-white font-bold py-3 px-6 rounded-lg hover:bg-teal-600 transition-colors duration-300"
                    >
                      RSVP to Event
                    </button>
                  )}
                </div>

                {/* Status Messages */}
                {message && (
                  <div className="mt-4 p-4 bg-mustard bg-opacity-20 rounded-lg">
                    <p className="text-teal font-medium">{message}</p>
                  </div>
                )}

                {/* Share Section */}
                <div className="mt-8 border-t pt-6">
                  <h3 className="font-display text-xl font-bold text-teal mb-4">Share Event</h3>
                  <div className="flex space-x-4">
                    <EmailShareButton url={shareUrl} subject={event.title} body={event.description}>
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                    <FacebookShareButton url={shareUrl} quote={event.title}>
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl} title={event.title}>
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <LinkedinShareButton url={shareUrl} title={event.title}>
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                    <WhatsappShareButton url={shareUrl} title={event.title}>
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Sign Up Modal */}
      {showSignup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full mx-4">
            <h2 className="font-display text-2xl font-bold text-teal mb-6 text-center">
              Sign Up to Participate
            </h2>
            <GoogleSignInButton />
            <button
              onClick={() => setShowSignup(false)}
              className="mt-4 w-full bg-gray-200 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-300 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetails;