import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';  
import logo from '../assets/NeigbhorCoinLogo.png';
import WelcomeMessage from './WelcomeMessage'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faBuildingColumns, faMapMarker, faWrench} from '@fortawesome/free-solid-svg-icons';

function Navbar({ isAuthenticated, userEmail, userRole, roleDisplayNames, getDashboardLink }) {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);  // Track logged-in user state
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoClick = () => {
    if (isAuthenticated) {
      setShowWelcomeMessage(true);
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);  // Set user when they are logged in, null if not
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  const navLinkStyles = ({ isActive }) =>
    `font-bold transition-all duration-200 px-3 py-2 rounded-md
    ${isActive 
      ? 'bg-mustard text-teal' 
      : 'text-neutralLight hover:bg-teal-600 hover:text-mustard'
    }`;

  return (
    <nav className="bg-teal text-neutralLight p-4 shadow-lg">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        {/* Logo and Brand Name */}
        <div className="flex items-center space-x-4">
          <NavLink to="/" className="flex items-center" onClick={handleLogoClick}>
            <img src={logo} alt="NeighborCoin Logo" className="w-8 h-8 mr-2" />
            <span className="text-2xl font-bold text-neutralLight text-shadow">NeighborhoodCoins</span>
          </NavLink>
        </div>
        {/* Hamburger menu for small screens */}
        <div className="block lg:hidden">
          <button onClick={toggleMenu} className="text-neutralLight focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
        {/* Links for larger screens */}
        <div className="hidden lg:flex items-center space-x-2">
          <NavLink to="/our-story" className={navLinkStyles}>
            Our Story
          </NavLink>
        
          <NavLink to="/how-it-works" className={navLinkStyles}>
            How it Works
          </NavLink>
      
          <NavLink to="/miami/events" className={navLinkStyles}>
            Events
          </NavLink>
          <NavLink to="/businesses" className={navLinkStyles}>
            Reward Partners
          </NavLink>

          <NavLink to="/news" className={navLinkStyles}>
            Neighborhood Watch
          </NavLink>
          {/* Conditional Links: Show 'Login' or 'Dashboard'/'Logout' based on user state */}
          {user ? (
            <>
              <NavLink to="/dashboard" className={navLinkStyles}>
                Dashboard
              </NavLink>
              <button
                onClick={() => getAuth().signOut()}
                className="font-bold px-3 py-2 rounded-md bg-coral hover:bg-coral-600 transition-colors duration-200"
              >
                Logout
              </button>
            </>
          ) : (
            <NavLink to="/login" className={navLinkStyles}>
              Login
            </NavLink>
          )}
        </div>
        
      </div>
      {/* Dropdown menu for small screens */}
      {isOpen && (
        <div className="lg:hidden">
          <div className="flex flex-col space-y-2 mt-4">
            <NavLink to="/our-story" className={navLinkStyles} onClick={toggleMenu}>
              Our Story
            </NavLink>
            <NavLink to="/how-it-works" className={navLinkStyles} onClick={toggleMenu}>
              How it Works
            </NavLink>
            <NavLink to="/miami/events" className={navLinkStyles} onClick={toggleMenu}>
              Events
            </NavLink>
            
            <NavLink to="/businesses" className={navLinkStyles} onClick={toggleMenu}>
              Reward Partners
            </NavLink>
            <NavLink to="/news" className={navLinkStyles} onClick={toggleMenu}>
              Neighborhood Watch
            </NavLink>
            

            {/* Conditional Links for Small Screens */}
            {user ? (
              <>
              <FontAwesomeIcon icon={faUserAlt} className="text-xl" />
                <NavLink to="/dashboard" className={navLinkStyles} onClick={toggleMenu}>
                  Dashboard
                </NavLink>
                <button
                  onClick={() => {
                    getAuth().signOut();
                    toggleMenu();
                  }}
                  className="hover:underline"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
           
              <NavLink to="/login" className={navLinkStyles} onClick={toggleMenu}>
                Login
              </NavLink>

              </>
            )}
            
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;